import React, { useState } from "react"

import { Link } from "gatsby"

import { graphql, useStaticQuery } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { BgImage } from "gbimage-bridge"

import Layout from "../components/layout"
import SEO from "../components/seo"

import M5Icon from "../images/svg/m5-icon.svg"
// import ScrollIcon from "../images/svg/scroll-icon.svg";
// import Banner from "../images/banner.png";

import BedIcon from "../images/svg/bed-icon.svg"
import ViewIcon from "../images/svg/view-icon.svg"
import BathIcon from "../images/svg/bath-icon.svg"
import FiveStarsIcon from "../images/svg/FiveStars.svg"

import WifiIcon from "../images/svg/wifi-icon.svg"
import ParkingIcon from "../images/svg/parking-icon.svg"
import PoolIcon from "../images/svg/pool-icon.svg"
import TvIcon from "../images/svg/tv-icon.svg"
import FoodIcon2 from "../images/svg/food-icon2.svg"
import LaundryIcon from "../images/svg/laundry-icon.svg"
import AirconIcon from "../images/svg/aircon-icon.svg"
import BbqIcon from "../images/svg/bbq-icon.svg"

import TripadvisorIcon from "../images/svg/tripadvisor-icon.svg"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Thumbs, Navigation, Pagination } from "swiper/core"

SwiperCore.use([Thumbs, Navigation, Pagination])

const IndexPage = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  const {
    room1,
    room2,
    room3,
    house1,
    landscape,
    feature1,
    feature2,
    feature3,
    feature4,
    feature5,
    TourismStars,
    TripRating,
  } = useStaticQuery(graphql`
    query {
      room1: file(relativePath: { eq: "roomOne/roomOne1.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }

      room2: file(relativePath: { eq: "room.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }

      room3: file(relativePath: { eq: "roomThree/roomThree1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }

      house1: file(relativePath: { eq: "house1.webp" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }

      landscape: file(relativePath: { eq: "landscape.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }

      feature1: file(relativePath: { eq: "feature1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }

      feature2: file(relativePath: { eq: "feature2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }

      feature3: file(relativePath: { eq: "feature3.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }

      feature4: file(relativePath: { eq: "feature4.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }

      feature5: file(relativePath: { eq: "feature5.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }

      TourismStars: file(relativePath: { eq: "TourismStars.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }

      TripRating: file(relativePath: { eq: "top-rated-trip.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  
  const room1Bg = getImage(room1)
  const room2Bg = getImage(room2)
  const room3Bg = getImage(room3)

  const feature1Bg = getImage(feature1)
  const feature2Bg = getImage(feature2)
  const feature3Bg = getImage(feature3)
  const feature4Bg = getImage(feature4)
  const feature5Bg = getImage(feature5)

  const landscapeBg = getImage(landscape)

  const house1Bg = convertToBgImage(getImage(house1))

  const TourismStarsBg = convertToBgImage(getImage(TourismStars))
  const TripRatingBg = convertToBgImage(getImage(TripRating))

  const bannerStack = [
    `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 3%, rgba(0,0,0,0.70) 100%)`,
  ].reverse()

  const roomStack1 = [
    room1Bg,
    `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 58%, rgba(0,0,0,1) 100%)`,
  ].reverse()

  const roomStack2 = [
    room2Bg,
    `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 58%, rgba(0,0,0,1) 100%)`,
  ].reverse()

  const roomStack3 = [
    room3Bg,
    `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 58%, rgba(0,0,0,1) 100%)`,
  ].reverse()

  const reviewStack = [
    landscapeBg,
    `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 58%, rgba(0,0,0,1) 100%)`,
  ].reverse()

  return (
    <Layout>
      <SEO
        title="Home"
        description="The Marine 5 Boutique Hotel near Gaansbaai is the best whale watching b&b in Hermanus South Africa"
      />

      <div className="container-2 banner">
        <div className="banner-content">
          <M5Icon />
          <h1>Marine 5</h1>
          <h2>We have been upgraded to Boutique Hotel status</h2>
          <FiveStarsIcon />
        </div>
        <StaticImage src="../images/banner.webp" loading="eager" alt="banner" className="banner-img"/>
        {/* try gatsby image as has cover option and eger load */}
      </div>

      <div className="container section">
        <div className="row about-con">
          <div className="col-md-6 no-padding">
            <BackgroundImage
              className="house-img"
              {...house1Bg}
            ></BackgroundImage>
          </div>

          <div className="col-md-6 ">
            <div className="about-info">
              <h3>Vacation in Hermanus South Africa</h3>
              <p>
                {" "}
                The Marine 5 in Stanfords Cove, is a full board luxury Boutique Hotel
                overlooking the Walker Bay Marine Big Five Conservation
                Area. We specialize in offering you all the luxuries you might
                need away from home. Nestled between rock and fynbos, our
                spacious sea facing rooms and fine South African dining will
                ensure your stay be a memorable one. Come and explore the area
                or just kick up your feet and enjoy all the amenities we have on
                offer. With only three sea facing suites, you are guaranteed
                privacy and luxury while you enjoy the beauty of Mother Nature.
                Come and experience Hermanus in South Africa firsthand and see
                why our guests recommend it.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="section home-rooms">
        <div className="container-2">
          <h3 className="section-heading">our rooms</h3>

          <div className="row">
            <div className="col-lg-4 no-padding room-col">
              <Link to="/rooms/">
                <BgImage className="room no-margin-left" image={roomStack1}>
                  <div className="room-name">
                    <h4>Suite Room</h4>
                  </div>

                  <div className="icons-con">
                    <div className="icon">
                      <span>
                        <BedIcon />
                        King size
                      </span>
                      <span>
                        <ViewIcon />
                        Sea View
                      </span>
                      <span>
                        <BathIcon />
                        En-suite
                      </span>
                    </div>
                  </div>
                </BgImage>
              </Link>
            </div>

            <div className="col-lg-4 no-padding room-col">
              <Link to="/rooms/">
                <BgImage className="room" image={roomStack2}>
                  <div className="room-name">
                    <h4>Queen Room Full En-suite</h4>
                  </div>

                  <div className="icons-con">
                    <div className="icon">
                      <span>
                        <BedIcon />
                        Queen size
                      </span>
                      <span>
                        <ViewIcon />
                        Sea View
                      </span>
                      <span>
                        <BathIcon />
                        En-suite
                      </span>
                    </div>
                  </div>
                </BgImage>
              </Link>
            </div>

            <div className="col-lg-4 no-padding room-col">
              <Link to="/rooms/">
                <BgImage className="room no-margin-right" image={roomStack3}>
                  <div className="room-name">
                    <h4>Queen Room En-Suite Shower Only</h4>
                  </div>

                  <div className="icons-con">
                    <div className="icon">
                      <span>
                        <BedIcon />
                        Queen size
                      </span>
                      <span>
                        <ViewIcon />
                        Sea View
                      </span>
                      <span>
                        <BathIcon />
                        En-suite
                      </span>
                    </div>
                  </div>
                </BgImage>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-2 feature-con">
          <div className="row">
            <div className="col-md-6 no-padding features-img-con">
              <main>
                {/* Main Swiper -> pass thumbs swiper instance */}
                <Swiper
                  thumbs={{ swiper: thumbsSwiper }}
                  navigation
                  pagination={{ clickable: true }}
                >
                  <SwiperSlide>
                    <BgImage
                      className="bg-com-slide"
                      image={feature1Bg}
                    ></BgImage>
                  </SwiperSlide>
                  <SwiperSlide>
                    <BgImage
                      className="bg-com-slide"
                      image={feature2Bg}
                    ></BgImage>
                  </SwiperSlide>
                  <SwiperSlide>
                    <BgImage
                      className="bg-com-slide"
                      image={feature3Bg}
                    ></BgImage>
                  </SwiperSlide>
                  <SwiperSlide>
                    <BgImage
                      className="bg-com-slide"
                      image={feature4Bg}
                    ></BgImage>
                  </SwiperSlide>
                  <SwiperSlide>
                    <BgImage
                      className="bg-com-slide"
                      image={feature5Bg}
                    ></BgImage>
                  </SwiperSlide>
                </Swiper>

                {/* Thumbs Swiper -> store swiper instance */}
                {/* It is also required to set watchSlidesVisibility and watchSlidesProgress props */}
                <Swiper
                  onSwiper={setThumbsSwiper}
                  watchSlidesVisibility
                  watchSlidesProgress
                  spaceBetween={20}
                  slidesPerView={5}
                  className="home-slider thumb-slider"
                >
                  <SwiperSlide>
                    <BgImage
                      className="bg-com-slide-thumb"
                      image={feature1Bg}
                    ></BgImage>
                  </SwiperSlide>
                  <SwiperSlide>
                    <BgImage
                      className="bg-com-slide-thumb"
                      image={feature2Bg}
                    ></BgImage>
                  </SwiperSlide>
                  <SwiperSlide>
                    <BgImage
                      className="bg-com-slide-thumb"
                      image={feature3Bg}
                    ></BgImage>
                  </SwiperSlide>
                  <SwiperSlide>
                    <BgImage
                      className="bg-com-slide-thumb"
                      image={feature4Bg}
                    ></BgImage>
                  </SwiperSlide>
                  <SwiperSlide>
                    <BgImage
                      className="bg-com-slide-thumb"
                      image={feature5Bg}
                    ></BgImage>
                  </SwiperSlide>
                </Swiper>
              </main>
            </div>

            <div className="col-md-6">
              <div className="about-info">
                <h3>FACILITIES</h3>
                <p>
                  {" "}
                  Should you have any additional requirements, please feel free
                  to contact us.{" "}
                </p>
                <div className="featured-info-icons">
                  <div className="home-icons-col home-icons">
                    <span>
                      <FoodIcon2 className="home-icon food" />
                      Room Service
                    </span>
                    <span>
                      <WifiIcon />
                      Free WiFi
                    </span>
                    <span>
                      <ParkingIcon />
                      Parking
                    </span>
                    <span>
                      <PoolIcon />
                      Heat Indoor Pool
                    </span>
                    <span>
                      <TvIcon />
                      DSTV
                    </span>
                  </div>

                  <div className="home-icons-col home-icons">
                    <span>
                      <LaundryIcon />
                      Laundry Service
                    </span>
                    <span>
                      <AirconIcon />
                      Aircon
                    </span>
                    <span>
                      <BbqIcon />
                      BBQ/Braai Facilities
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <BgImage className="container-2 review-con" image={reviewStack}>
          <div className="row">
            <div className="col-md-3">
              <div className="review-info">
                <h3 className="section-heading">Reviews</h3>
                <BackgroundImage
                  className="tourism-stars"
                  {...TourismStarsBg}
                ></BackgroundImage>
                <BackgroundImage
                  className="trip-rating"
                  {...TripRatingBg}
                ></BackgroundImage>
              </div>
            </div>

            <div className="col-md-9">
              <Swiper
                spaceBetween={5}
                navigation
                pagination={{ clickable: true }}
                className="review-slider"
                breakpoints={{
                  // when window width is >= 640px
                  640: {
                    //   width: 640,
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    //   width: 768,
                    slidesPerView: 2,
                  },
                  1600: {
                    //   width: 768,
                    slidesPerView: 3,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="review-card-con">
                    <div className="review-card">
                      <div className="review-card-icon top">
                        <TripadvisorIcon />
                      </div>

                      <div className="review-card-info">
                        <p>
                          "Lovely views, friendly staff, amazingly tasty food,
                          prepared by the Chef. Very impressed by the clean,
                          spacious rooms, and big bathroom with sea views.
                          Definitely worth a visit when in Gansbaai, and value
                          for money."
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="review-card-con">
                    <div className="review-card">
                      <div className="review-card-info">
                        <p>
                          "It was unique in marine5. We had very relaxing days
                          there. The guest house is very lovingly furnished and
                          the food was very good. The surroundings are very
                          beautiful and the accommodation is directly on the
                          beach. A great view."
                        </p>
                      </div>

                      <div className="review-card-icon bottom">
                        <TripadvisorIcon />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="review-card-con">
                    <div className="review-card">
                      <div className="review-card-icon top">
                        <TripadvisorIcon />
                      </div>

                      <div className="review-card-info">
                        <p>
                          "Superb service, one of the most gorgeous rooms we
                          have ever stayed in (the floor to ceiling windows and
                          view are unbelievable) and the food and service
                          (thanks Reggie, Lindi and Bella) was a delight. We
                          loved every minute."
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="review-card-con">
                    <div className="review-card">
                      <div className="review-card-info">
                        <p>
                          "We had a very wonderful stay in this place. You feel
                          like being part of the Family. Right from the start
                          everything was perfect and we had a very special new
                          years eve. We will never forget that! Breakfast is
                          very good and also the dinner in the evening. We will
                          come back definitely!"
                        </p>
                      </div>

                      <div className="review-card-icon bottom">
                        <TripadvisorIcon />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </BgImage>
      </section>
    </Layout>
  )
}

export default IndexPage
